import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AppDataContext from '../common/AppContext';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '5px',
        display: 'flex',
        backgroundColor: 'black',
        zIndex: theme.zIndex.drawer + 1,
        borderRadius: theme.spacing(0),
    },
    copyright: {
        marginLeft: 'auto',
        color: "white"
    }

}));


const FooterBar = () => {
    const classes = useStyles();

    const {
        applicationVersion
    } = useContext(AppDataContext);

    return (
        <Paper elevation={6} className={classes.root}>            
            <Typography variant="caption" className={classes.copyright}>© 2023 NBA Properties, Inc. All rights reserved.</Typography>
        </Paper>
    )
}

export default FooterBar;